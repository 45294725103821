
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































































$br: 0.8rem;
$bp: m;

.cta-moodboard-inner {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  border-radius: $br;
  box-shadow: $card-shadow;

  @include mq($bp) {
    flex-direction: row-reverse;
  }
}

.cta-moodboard__content__title {
  ::v-deep em {
    @extend %fw-light;

    // Same size as h2--small
    font-size: 2.4rem;

    @include mq(l) {
      font-size: 3.6rem;
    }

    @include mq(xxl) {
      font-size: 4rem;
    }
  }
}

.cta-moodboard__content {
  padding: $spacing;
  background: $c-white;
  border-radius: 0 0 $br $br;

  @include mq($bp) {
    flex: 1;
    padding: $spacing * 2 $spacing * 2 $spacing * 2 col(1.25, 10);
    border-radius: 0 $br $br 0;
  }

  @include mq(xl) {
    padding: $spacing * 5 $spacing * 5 $spacing * 4 col(2.5, 10);
  }
}

.cta-moodboard__content__text,
.cta-moodboard__content__link {
  margin-top: $spacing;

  @include mq($bp) {
    margin-top: $spacing * 2;
  }
}

.cta-moodboard__background {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: $br $br 0 0;

  img {
    @include image-fit;
  }

  @include mq($bp) {
    width: col(4, 10);
    aspect-ratio: auto;
    border-radius: $br 0 0 $br;
  }

  @include mq(xl) {
    width: col(3, 10);
  }
}

.cta-moodboard__picture {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;

  img {
    @include image-fit(contain);
  }

  .cta-moodboard__background + & {
    position: absolute;
    z-index: 1;
    top: 0;
  }

  @include mq($bp) {
    width: col(4, 10);
    margin-right: col(-1, 10);
    aspect-ratio: auto;

    .cta-moodboard__background + & {
      top: $spacing * 0.5;
      left: col(2, 10);
      width: col(3, 10);
      height: calc(100% - #{$spacing});
      margin: 0;
      aspect-ratio: auto;
    }
  }

  @include mq(xl) {
    width: col(3, 10);
    margin-right: col(-2, 10);
    margin-left: col(0.5, 10);
  }
}
